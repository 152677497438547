<template>
    <v-card class="delete_card">
        <v-row no-gutters>
            <v-col cols="3" class="pa-2">
                <v-img :src="rowData.product_img"
                       height="70px"
                       width="70px"
                       style="cursor: pointer"/>
            </v-col>

            <v-col cols="7" class="align-self-center">
                <h6 class="gift_title font-weight-medium ellipsis">
                    {{ rowData.product_name }}
                </h6>
                <h6 class="sale_price font-weight-medium">
                    {{ formatPrice(rowData.sale_price) }}
                </h6>
                <h6 class="reg_date font-weight-medium grey--text">
                    {{ rowData.update_date }}
                </h6>
            </v-col>

            <!-- <v-col cols="3" class="align-self-center" style="text-align: -webkit-center">
                <v-btn small
                       color="primary"
                       outlined
                       @click="permanentDelete"
                >
                    영구삭제
                </v-btn>
            </v-col> -->
            <!-- <v-col cols="2" class="align-self-center" style="text-align: -webkit-center">
                <v-btn small
                       color="#5EB4F9"
                       outlined
                       @click="delData"
                >
                    복구
                </v-btn>
            </v-col> -->
        </v-row>

    </v-card>
</template>

<script>
    export default {
        name: "giftcard",
        props: {
            rowData: {
                type: Object,
                require: false
            }
        },
        methods:{
            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'원';
                }
                catch{
                    return value+'원';
                }
            },

            delData(){
                this.$emit('childs-event', this.$props.rowData);
            },

            permanentDelete(){
                this.$emit('perma-event', this.$props.rowData);
            },
        }
    }
</script>

<style lang="scss">
    .gift_title{
        font-size: 0.875rem;
        letter-spacing: -0.05em;
    }
    .reg_date{
        font-size: 0.7rem;
        letter-spacing: -0.03em;
    }
    .sale_price{
        font-size: 0.825rem;
        color: #5EB4F9;
        letter-spacing: -0.03em;
    }
    .delete_card{
        border-bottom: .8px solid rgb(0 0 0 / 12%) !important;
    }
    .ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<style lang="scss" scoped>
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }
    .v-sheet.v-card{
        border-radius: initial !important;
    }
</style>
